@import '../../node_modules/bootstrap/scss/bootstrap'
@import '../base.sass'

#nav-container
    position: relative
    top: 0px
    @include mobile
        top: -$navbar_offset !important
    > #nav-banner
        position: fixed
        left: 0
        right: 0
        top: 0
        @include mobile
            top: -$navbar_offset !important
    > #nav-logo
        position: fixed
        left: 26px
        top: 26px
        @include mobile
            top: calc(26px - $navbar_offset) !important
    > #nav-linkbar-container
        position: fixed
        left: 0
        right: 0
        top: $navbar_offset
        bottom: 40px
        height: 40px
        @include mobile
            bottom: 78px
            height: 78px
        box-shadow: 0 6px 4px $color_shadow
        @include mobile
            top: 0 !important
        > #nav-linkbar
            top: $navbar_offset
            padding: 0 26px 0 26px
            background: $color_nav_bg
            @include mobile
                top: 0 !important
            &[collapsed]
                top: 0
            > #nav-brand
                padding: 0
                > img
                    height: 40px
                    @include mobile
                        height: 56px
            > .navbar-toggler-icon
                color: white

.nav-toggle
    padding: 2px
    margin: 8px 12px
    &.collapsed
        margin: 4px
    > span
        width: 26px
        height: 26px
        @include mobile
            margin: 8px
            padding: 8px
            width: 50px
            height: 50px

.nav-link
    @include hover-underline-light
    color: white !important
    font-size: 20px
    @include mobile
        font-size: 28px
    font-weight: 300
    padding: 0 !important
    margin: 4px 12px

.nav-lang-container
    // height: 24px
    margin: 4px 12px
    color: white
    font-weight: 400
    @include mobile
        padding: 0 4px
    > span
        cursor: pointer
        font-size: 16px
        @include mobile
            font-size: 24px
        @include hover-underline-light
        &[active=true]
            border-bottom: 1px solid white

.dropdown-item
    @include mobile
        font-size: 22px