@import '../base.sass'
@import '../Basic.sass'

.footer
    @include allow-select
    width: 100%
    background-color: $color_nav_bg
    margin-top: 24px
    padding: 12px 0
    img
        width: 24px
        height: 24px
        filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(113deg) brightness(101%) contrast(101%)
        margin: 8px

.footer-address
    @include recognize-linebreak
    width: max-content
    text-align: center
    color: white
    margin: 4px auto

.footer-phone
    width: max-content
    text-align: center
    color: white
    margin: 4px auto

.footer-website
    width: max-content
    text-align: center
    margin: 4px auto
    a
        text-decoration: none
        @include hover-underline-light
        color: white
