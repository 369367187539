.TClogo
    height: 46px
    position: absolute
    top: 106px
    left: 26px

.linkbar
    height: 40px
    position: absolute
    top: 110px
    right: 0px